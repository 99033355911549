<template>
  <div>
    <div v-if="!is_sup">
      <form class="input-group" @submit.prevent="access(pin)">
        <input
          class="form-control"
          placeholder="PIN"
          type="password"
          v-model="pin"
        />
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="access(pin)"
          >
            Access !
          </button>
        </div>
      </form>
      <br />
      <personal-desh />
    </div>

    <b-input-group>
      <b-input-group-prepend>
        <b-form-select v-model="month" :options="monthoptions" />
      </b-input-group-prepend>
      <b-input-group-append>
        <b-form-select v-model="year" :options="yearoptions" />
        <!-- <b-button variant="outline-primary" @click="getdata()">
          GET !
        </b-button> -->
      </b-input-group-append>
    </b-input-group>
    <br />
    <div v-if="is_sup">
      <div class="row">
        <div class="col-12 mb-1">
          <form class="input-group" @submit.prevent="gettran()">
            <!-- basic search -->
            <input
              class="form-control"
              placeholder="username"
              list="user-list"
              type="text"
              v-model="user"
            />
            <datalist id="user-list">
              <option value="All">All</option>
              <option
                :value="d.username"
                v-for="(d, index) in predata"
                :key="index"
              >
                {{ d.first_name }} {{ " " }} {{ d.last_name }}
              </option>
            </datalist>
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-outline-primary"
                @click="gettran()"
              >
                Search !
              </button>
            </div>
          </form>
        </div>
      </div>
      <div v-if="is_data">
        <b-card no-body class="card-statistics">
          <b-card-header>
            <b-card-title
              >Statistics
              <span style="color: green">({{ user }})</span></b-card-title
            >
            <b-card-text class="mr-25 mb-0">
              <a href="#" @click="gettran()">
                Refresh
                <b-badge variant="light-primary">
                  {{ total }}
                </b-badge>
              </a>
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                v-for="item in statisticsItems"
                :key="item.icon"
                md="3"
                sm="6"
                class="mb-2 mb-md-0"
                :class="item.customClass"
              >
                <b-media-aside no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" :variant="item.color">
                      <feather-icon size="24" :icon="item.icon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ item.title }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ item.subtitle }}
                    </b-card-text>
                  </b-media-body>
                </b-media-aside>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-table small :fields="fields" :items="items" responsive="sm">
          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }} /
            <b-badge pill>
              <span>{{ data.item.id }}</span>
            </b-badge>
          </template>

          <!-- A custom formatted column -->
          <template #cell(gateway_tran_id)="data">
            <!-- <b-badge variant="light-primary">
          {{ data.value }}
        </b-badge> -->
            <b-badge pill variant="primary" v-if="data.item.is_auth">
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>{{ data.value }}</span>
            </b-badge>
            <b-badge
              href="#"
              @click="updatetran(data.item.id)"
              variant="danger"
              v-else
            >
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>{{ data.value }}</span>
            </b-badge>
          </template>
          <!-- A custom formatted column -->
          <template #cell(tran_val)="data">
            <b-badge
              pill
              variant="primary"
              v-if="data.value == 1"
              @click="modaldata(data.index)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal
            >
              <span>DTM-PAY</span>
            </b-badge>
            <b-badge
              pill
              variant="warning"
              v-else-if="data.value == 6"
              @click="modaldata(data.index)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal
            >
              <span>SUNDY-PAY</span>
            </b-badge>
            <b-badge
              pill
              v-else-if="data.value == 2"
              @click="modaldata(data.index)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal
            >
              <span>REG-PAY</span>
            </b-badge>
            <b-badge
              pill
              v-else
              @click="modaldata(data.index)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal
            >
              <span>Donate-PAY</span>
            </b-badge>
          </template>

          <!-- A custom formatted column -->
          <template #cell(Popularity)="data">
            <b-progress
              :value="data.value.value"
              max="100"
              :variant="data.value.variant"
              striped
            />
          </template>
          <!-- A custom formatted column -->
          <template #cell(pay_type)="data">
            <b-badge
              variant="dark"
              class="badge-glow"
              v-if="data.value == 'Cash'"
            >
              {{ data.value }}
            </b-badge>
            <b-badge class="badge-glow" v-else>
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(is_auth)="data">
            <b-badge variant="success" v-if="data.value">
              <feather-icon icon="ShieldIcon" class="mr-25" />
              <span> Verified </span>
            </b-badge>
            <b-badge
              href="#"
              @click="postauth(data.item.id)"
              variant="danger"
              v-else
            >
              <feather-icon icon="ShieldOffIcon" class="mr-25" />
              <span>Unverified</span>
            </b-badge>
            <b-badge
              href="#"
              @click="postcancel(data.item.id)"
              variant="danger"
              v-if="!data.value"
              class="ml-25"
            >
              <!-- <feather-icon icon="CancelIcon" class="mr-25" /> -->
              <span>Cancel</span>
            </b-badge>
          </template>

          <!-- A virtual composite column -->
          <template #cell(total_amount)="data">
            <b-badge pill variant="primary">
              {{ "৳" + data.value }}
            </b-badge>
          </template>

          <!-- Optional default data cell scoped slot -->
          <template #cell(tran_date)="data">
            <b-badge pill variant="dark">{{ data.value }}</b-badge>
          </template>
          <!-- Optional default data cell scoped slot -->
          <template #cell(pay_status)="data">
            <a
              @click="
                openPortal(`https://api.yfbd.org/dtm/invoice/${data.item.id}/`)
              "
            >
              <b-badge variant="success">
                <feather-icon icon="PrinterIcon" />
              </b-badge>
            </a>
          </template>
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
      </div>
    </div>
    <!-- model view  -->

    <b-modal
      id="modal"
      ok-only
      ok-title="Close"
      centered
      size="lg"
      :title="`Transaction Details ( ID: ${info.id} )`"
    >
      <b-card-text>
        <div class="row">
          <div class="col-2 mb-1">
            <b-badge pill variant="primary" v-if="info.tran_val == 1">
              <span>DTM-PAY</span>
            </b-badge>
            <b-badge pill variant="warning" v-else-if="info.tran_val == 6">
              <span>SUNDY-PAY</span>
            </b-badge>
            <b-badge pill v-else>
              <span>REG-PAY</span>
            </b-badge>
          </div>

          <div class="col-1 mb-1">
            <b-badge
              variant="dark"
              class="badge-glow"
              v-if="info.pay_type == 'Cash'"
            >
              {{ info.pay_type }}
            </b-badge>
            <b-badge class="badge-glow" v-else>
              {{ info.pay_type }}
            </b-badge>
          </div>
          <div class="col-9 mb-1">
            <b-badge variant="success" v-if="info.is_auth">
              <feather-icon icon="ShieldIcon" class="mr-25" />
              <span> Verified </span>
            </b-badge>
            <b-badge
              href="#"
              @click="postauth(info.id)"
              variant="danger"
              v-else
            >
              <feather-icon icon="ShieldOffIcon" class="mr-25" />
              <span>Unverified</span>
            </b-badge>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mb-1">
            <label for="tran">Trx ID</label>
            <input
              class="form-control"
              placeholder="Trx ID"
              type="text"
              id="tran"
              v-model="info.gateway_tran_id"
              readonly
            />
          </div>
          <div class="col-12 col-lg-3 mb-1">
            <label for="from">From MFS</label>
            <input
              class="form-control"
              placeholder="Mobile Number"
              type="text"
              id="from"
              v-model="info.number"
              readonly
            />
          </div>
          <div class="col-12 col-lg-3 mb-1">
            <label for="to">To MFS</label>
            <input
              class="form-control"
              placeholder="N/A"
              type="text"
              id="to"
              v-model="info.account"
              readonly
            />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="ptype">Payment Type</label>
            <input
              class="form-control"
              placeholder="Account Status"
              type="ptype"
              v-model="info.pay_type"
              readonly
            />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="trandate">Trx Date</label>
            <input
              class="form-control"
              placeholder="Opening Date"
              type="text"
              id="trandate"
              v-model="info.tran_date"
              readonly
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mb-1 input-group input-group-merge">
            <div class="input-group-prepend">
              <div class="input-group-text">Store Amount</div>
            </div>
            <input
              class="form-control"
              placeholder="Panding Subcription Fee"
              type="number"
              v-model="info.store_amount"
              readonly
            />
            <div class="input-group-append">
              <div class="input-group-text">৳</div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-1 input-group input-group-merge">
            <div class="input-group-prepend">
              <div class="input-group-text">Total Amount</div>
            </div>
            <input
              class="form-control"
              placeholder="Advance Subcription Fee"
              type="number"
              v-model="info.total_amount"
              readonly
            />
            <div class="input-group-append">
              <div class="input-group-text">৳</div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12 col-lg-6 mb-1">
            <label for="created">Created By</label>
            <input
              class="form-control"
              placeholder="Created By"
              type="text"
              id="created"
              v-model="info.created_by"
              readonly
            />
          </div>
          <div class="col-12 col-lg-3 mb-1">
            <label for="city1">City</label>
            <input
              class="form-control"
              placeholder="City"
              type="text"
              id="city1"
              v-model="ip.city"
              readonly
            />
          </div>
          <div class="col-12 col-lg-3 mb-1">
            <label for="country">Country</label>
            <input
              class="form-control"
              placeholder="Country"
              type="text"
              id="country"
              v-model="ip.country"
              readonly
            />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="isp">Internet Service Provider</label>
            <input
              class="form-control"
              placeholder="Internet Service Provider"
              type="isp"
              v-model="ip.organization_name"
              readonly
            />
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <label for="ip">IP Address</label>
            <input
              class="form-control"
              placeholder="IP Address"
              type="text"
              id="ip"
              v-model="info.ip_address"
              readonly
            />
          </div>
        </div>
        <div v-if="info.tran_val == 1">
          <hr />
          <div class="row">
            <div class="col-12 col-lg-6 mb-1">
              <label for="fullName">Full Name</label>
              <input
                class="form-control"
                placeholder="Full Name"
                type="fullName"
                v-model="fullName"
                readonly
              />
            </div>
            <div class="col-12 col-lg-6 mb-1">
              <label for="family">{{ familyType }}'s Name</label>
              <input
                class="form-control"
                :placeholder="familyType"
                type="text"
                id="family"
                v-model="familyName"
                readonly
              />
            </div>
            <div class="col-12 col-lg-6 mb-1">
              <label for="email">Email</label>
              <input
                class="form-control"
                placeholder="example@example.com"
                type="text"
                id="email"
                v-model="email"
                readonly
              />
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <label for="mobile1">mobile1</label>
              <input
                class="form-control"
                placeholder="Mobile"
                type="text"
                id="mobile1"
                v-model="mobile1"
                readonly
              />
            </div>
            <div class="col-12 col-lg-3 mb-1">
              <label for="mobile2">mobile2</label>
              <input
                class="form-control"
                placeholder="Mobile2"
                type="text"
                id="mobile2"
                v-model="mobile2"
                readonly
              />
            </div>

            <div class="col-12 col-lg-6 mb-1">
              <label for="dtm">DTM ID</label>
              <input
                class="form-control"
                placeholder="DTM ID"
                type="text"
                id="dtm"
                v-model="dtm_id"
                readonly
              />
            </div>
            <div class="col-12 col-lg-6 mb-1">
              <label for="dateofbirth">Date of Birth</label>
              <input
                class="form-control"
                placeholder="Date of Birth"
                type="dateofbirth"
                v-model="dateOfBirth"
                readonly
              />
            </div>
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BTable,
  BProgress,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
  BButton,
  BModal,
  VBModal,
} from "bootstrap-vue";
import PersonalDesh from "../extensions/card-statistic/CardStatisticsGroup.vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";

export default {
  components: {
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BTable,
    BProgress,
    BBadge,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    PersonalDesh,
    BButton,
    BModal,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      user: "",
      data: "",
      pin: "",
      is_data: false,
      is_sup: false,
      fields: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "dtm_id", label: "DTM ID" },
        { key: "tran_date", label: "Transaction Date" },
        { key: "tran_val", label: "Tran Type" },
        "pay_type",
        // A regular column
        { key: "gateway_tran_id", label: "Tnx/Money Recept" },
        // A regular column
        { key: "is_auth", label: "Auth Status" },
        // A virtual column made up from two fields
        { key: "total_amount", label: "Total Amount" },
        { key: "pay_status", label: "Action" },
      ],
      month: "",
      year: "",
      monthoptions: [
        { value: 1, text: "Jan" },
        { value: 2, text: "Feb" },
        { value: 3, text: "Mar" },
        { value: 4, text: "Apr" },
        { value: 5, text: "May" },
        { value: 6, text: "Jun" },
        { value: 7, text: "Jul" },
        { value: 8, text: "Aug" },
        { value: 9, text: "Sep" },
        { value: 10, text: "Oct" },
        { value: 11, text: "Nov" },
        { value: 12, text: "Dec" },
      ],
      yearoptions: [],
      items: [],
      info_id: "",
      info: {},
      fullName: "",
      dateOfBirth: "",
      mobile1: "",
      mobile2: "",
      dtm_id: "",
      email: "",
      familyType: "",
      familyName: "",
      dtm_info: {
        dtm_profile: {
          fullName: "",
        },
      },
      ip: {},
      predata: [],
      total: "৳0",
      statisticsItems: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: "0/0",
          subtitle: "Total/Monthly Transaction",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserIcon",
          color: "light-info",
          title: "0/0",
          subtitle: "Total/Monthly DTM Registration",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "AwardIcon",
          color: "light-success",
          title: "৳0",
          subtitle: "This Month`s Collection",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "DollarSignIcon",
          color: "light-danger",
          title: "৳0",
          subtitle: "Panding Amount",
          customClass: "",
        },
      ],
    };
  },
  computed: {},
  methods: {
    openPortal(link) {
      this.windowRef = window.open(
        link,
        "Money Recept - Print",
        "width=800,height=1200,left=200,top=200"
      );
    },
    modaldata(index) {
      const that = this;
      this.info_id = index;
      if (that.items[index].tran_val == 1) {
        that.getdtm_info(that.items[index].dtm_id);
      } else {
        that.dtm_info = {};
      }
      this.info = {};
      this.info = this.items[index];
      this.getip(this.items[index].ip_address);
    },
    access(pin) {
      if (!pin == "") {
        this.$http
          .post(`/dtm/auth/pay/checkpin/all/`, {
            pin: pin,
          })
          .then((response) => {
            this.is_sup = response.data.is_sup;
            if (response.data.message == false) {
              let variant = "success";
              this.$bvToast.toast("PIN Verified ", {
                title: `Access Success`,
                variant,
                solid: true,
              });
            } else {
              let variant = "warning";
              this.$bvToast.toast("PIN Incorrect", {
                title: `Access Faild`,
                variant,
                solid: true,
              });
            }
          })
          .catch((error) => console.log(error));
      } else {
        let variant = "danger";
        this.$bvToast.toast("PIN is Required", {
          title: `Access Faild`,
          variant,
          solid: true,
        });
      }
    },
    postauth(in_id) {
      console.log(in_id);
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);

      if (in_id != "") {
        that
          .$swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, Approve!",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm() {
              that.$http
                .post(`/dtm/auth/pay/authpay/${in_id}/`, {
                  user: that.user,
                })
                .then((response) => {
                  that.gettran();
                  console.log(response);
                })
                .catch((error) => {
                  that.gettran();
                  console.log(error);
                });
            },
          })
          .then((result) => {
            if (result.value) {
              that.$swal({
                icon: "success",
                title: "Approved!",
                text: "Payment has been Approved.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              that.gettran();
              // that.info = that.items[that.info_id];
            }
          });
      } else {
        let variant = "danger";
        that.$bvToast.toast("Transaction ID is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
     postcancel(in_id) {
      console.log(in_id);
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);

      if (in_id != "") {
        that
          .$swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, Cancel!",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm() {
              that.$http
                .post(`/dtm/auth/pay/cancel/${in_id}/`, {
                  user: that.user,
                })
                .then((response) => {
                  that.gettran();
                  console.log(response);
                })
                .catch((error) => {
                  that.gettran();
                  console.log(error);
                });
            },
          })
          .then((result) => {
            if (result.value) {
              that.$swal({
                icon: "success",
                title: "Cancel!",
                text: "Payment has been Canceled.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              that.gettran();
              // that.info = that.items[that.info_id];
            }
          });
      } else {
        let variant = "danger";
        that.$bvToast.toast("Transaction ID is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    updatetran(id) {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      console.log(userdata);
      console.log(that.selected);

      if (id != "") {
        this.$swal({
          title: "Update Money Recept Number",
          input: "text",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Update",
          showLoaderOnConfirm: true,
          preConfirm(number) {
            that.$http
              .post(`/dtm/auth/pay/updateTran/${id}/`, {
                gateway_tran_id: number,
              })
              .then((response) => {
                that.gettran();
                console.log(response);
              })
              .catch((error) => {
                // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                that.gettran();
                console.log(error);
              });
          },
        }).then((result) => {
          if (result.value) {
            this.$swal({
              title: `Updated`,
              // imageUrl: result.value.avatar_url,
              customClass: { confirmButton: "btn btn-primary" },
            });
            that.gettran();
          }
        });
      } else {
        that.gettran();
        let variant = "danger";
        this.$bvToast.toast("Transaction Id is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    pre() {
      this.$http
        .get(`/dtm/reg/pay/getuserall/all/`)
        .then((response) => {
          this.predata = response.data;
        })
        .catch((error) => console.log(error));
    },

    gettran() {
      var userdata = JSON.parse(localStorage.getItem("userData"));
      if (!this.user == "") {
        // /dtm/reg/pay/alltrandata/${this.user}/
        this.$http
          .get(`/dtm/authdata/${this.user}/${this.month}/${this.year}/`)
          .then((response) => {
            this.items = response.data;
          })
          .catch((error) => console.log(error));
        this.$http
          .get(`/dtm/reg/pay/userDash/${this.user}/`)
          .then((response) => {
            var data = response.data.account;
            this.statisticsItems[0].title =
              data.total_tran + "/" + data.this_month_tran;
            this.statisticsItems[1].title =
              data.total_reg + "/" + data.this_month_reg;
            this.statisticsItems[2].title = `৳` + data.this_month_amount;
            this.statisticsItems[3].title = `৳` + data.panding_amount;
            this.total = `৳` + data.total_amount;
          })
          .catch((error) => console.log(error));
        this.is_data = true;
      } else {
        let variant = "danger";
        this.$bvToast.toast("username is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    getdtm_info(id) {
      const that = this;
      if (!id == "") {
        this.$http
          .get(`/dtm/reg/pay/dtm/${id}/`)
          .then((response) => {
            if (response.data.message) {
              that.cleandata();
              let variant = "danger";
              this.$bvToast.toast("User Not Found", {
                title: `Error`,
                variant,
                solid: true,
              });
            } else {
              this.dtm_info = response.data;
              this.fullName = response.data.dtm_profile.fullName;
              this.dateOfBirth = response.data.dtm_profile.dateOfBirth;
              this.mobile1 = response.data.dtm_profile.mobile1;
              this.mobile2 = response.data.dtm_profile.mobile2;
              this.dtm_id = response.data.dtm_id;
              this.email = response.data.dtm_profile.email;
              this.familyType = response.data.dtm_profile.familyType;
              this.familyName = response.data.dtm_profile.familyName;
            }
          })
          .catch((error) => console.log(error));
      } else {
        let variant = "danger";
        this.$bvToast.toast("Pls Enter Customer Id", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    async getip(ip) {
      await axios
        .get(`https://get.geojs.io/v1/ip/geo/${ip}.json`)
        .then((response) => {
          this.ip = response.data;
        })
        .catch((error) => console.log(error));
    },
    getYears() {
      let start = 2020;
      const date = new Date();
      let end = date.getFullYear();
      this.month = date.getMonth() + 1;
      console.log(date.getMonth() + 1);
      this.year = date.getFullYear();
      console.log(end);
      for (start; start <= end; start++) {
        this.yearoptions.push({ value: start, text: start });
      }
    },
  },
  created() {
    this.getYears();
    // this.gettran();
    this.pre();
    var userdata = JSON.parse(localStorage.getItem("userData"));
    this.is_sup = userdata.is_sup;
  },
};
</script>
